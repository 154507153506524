body table.stack {
  @include breakpoint(medium down) {
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
    tr {
      display: table-row;
    }
    th, td {
      display: table-cell;
      border-top: initial;
    }
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: none;

  &:hover {
    color: $black!important;;
    background: #ededed;
    border: none;
  }

  &.current,
  &.current:hover {
    color: $white!important;
    background: $primary-color;
    border: none;
  }
}

//this creates a breakpoint at small
body table.stack {
  @include breakpoint(small down) {
    @include table-stack;
  }
}