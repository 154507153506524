@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/iconfont/icomoon.eot?ko2qk3');
  src: url('../../fonts/iconfont/icomoon.eot?ko2qk3#iefix') format('embedded-opentype'),
  url('../../fonts/iconfont/icomoon.ttf?ko2qk3') format('truetype'),
  url('../../fonts/iconfont/icomoon.woff?ko2qk3') format('woff'),
  url('../../fonts/iconfont/icomoon.svg?ko2qk3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icomoon-icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  @extend %icomoon-icon;
}

.icon-envelope-open:before {
  content: "\e900";
}

.icon-envelope:before {
  content: "\e901";
}

.icon-envelope-solid-open-text:before {
  content: "\e902";
}

.icon-envelope-solid-open:before {
  content: "\e903";
}

.icon-envelope-solid:before {
  content: "\e904";
}

.icon-file-pdf:before {
  content: "\e905";
}