$background: #f9f9f9;
$border: #000;

.quick-menu {
  border-radius: 0.5rem;

  .quick-menu-item {
    background: $background;
    border: 1px solid $border;

    &:hover {
      background: darken($background, 5%);
    }

    a {
      display: block;
      padding: 1rem;
      width: 100%;
      height: 100%;

      font-size: 3rem;
    }
  }
}