.ring-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  .button & {
    width: 20px;
    height: 20px;
    position: absolute;
    display: block;
    right: 0;

    div {
      border-color: $white transparent transparent transparent;
      width: 15px;
      height: 15px;
    }
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $primary-color;
    border-radius: 50%;
    animation: ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary-color transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes ring-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


body {
  & > .loader-overlay {
    z-index: 9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    background: rgba(10, 10, 10, 0.6);
    visibility: hidden;
    opacity: 0;

    &.is-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  & > .ring-loader {
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.off-canvas {
  .ring-loader {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > .loader-overlay {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    background: rgba(10, 10, 10, 0.2);
    visibility: hidden;
    opacity: 0;

    &.is-visible {
      visibility: visible;
      opacity: 1;
    }
  }
}