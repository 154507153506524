.main-header {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  .logged-in-as {
    padding: 0.7rem 1rem 0;
    font-weight: bold;
  }

  @include breakpoint('medium') {
    .navigation {
      justify-content: flex-end;
    }
  }
}
