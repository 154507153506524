@import 'settings';
@import 'foundation';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;

// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
//@include foundation-responsive-embed;
@include foundation-tabs;
//@include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

@import "~datatables.net-dt/css/jquery.dataTables.min.css";
//@import "~datatables.net-zf/css/dataTables.foundation.min.css";

// @see: https://foundation.zurb.com/building-blocks/blocks/alert-callout-border.html
//@import 'app/callout-extension';
//@import 'app/misc';
//@import 'app/amount-field';
//@import 'app/elements';
//@import 'app/font';
@import 'app/loader';
@import 'app/document-results';
@import 'app/iconfont';
@import 'app/index';
@import 'app/header';
@import 'app/quick-menu';
@import 'app/table';
//@import 'app/footer';
//@import 'app/search';
//@import 'app/slider';
//@import 'app/offcanvas';
//@import 'app/sidebar';
//@import 'app/breadcrumbs';
//@import 'app/product-listing';
//@import 'app/product-detail';
//@import 'app/groups';
//@import 'app/cart';
//@import 'app/shopping-list';
//@import 'app/checkout';
//@import 'app/checkout-progress-bar';
//@import 'app/registration';
//@import 'app/language-switcher';
//@import 'app/account';
//@import 'app/pagination';
//@import 'app/cookie-notice';
