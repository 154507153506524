body.is-ctl-inbox.is-act-index {
  .grid-container.content {
    padding-bottom: 1rem;
  }

  .result-table-wrapper {
    table {
      margin-bottom: 0;
    }
  }
}